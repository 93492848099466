<template>
  <el-main>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="this.type == 'edit' ? $t('cip.plat.sys.serialNumber.title.editHeadTitle') : (this.type == 'view' ? $t('cip.plat.sys.serialNumber.title.viewHeadTitle') : $t('cip.plat.sys.serialNumber.title.addHeadTitle'))"
                 @head-save="submitbtn()"
                 @head-save-back="submitbtn('back')"
                 @head-cancel="close">
    </head-layout>
    <form-layout :group.sync="group" ref="formLayout" :dataForm.sync="form"></form-layout>
  </el-main>
</template>
<script>
import {getDetail, saveOrUpdate} from "@/api/system/serialNumber";
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {mapGetters} from "vuex";
export default {
  components:{FormLayout,HeadLayout,GridLayout},
  data() {
    return {
      isClose:false,
      itemData: [],
      viewFlag: true,//是否展示按钮
      editFlag: false,
      saveLoading: false,
      type: 'view',
      form: {
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission","language"]),
    headBtnOptions(){
      let result = [];
      if (!this.viewFlag) {
        result.push({
          label: this.$t('cip.cmn.btn.saveBtn'),
          emit: "head-save",
          type: "button",
          icon: ""
        });
        result.push({
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        });
      }
      result.push({
        label: this.$t('cip.cmn.btn.celBtn'),
        emit: "head-cancel",
        type: "button",
        icon: ""
      });
      return result;
    },
    group(){
      let validatePass = (rule, value, callback) => {
        if (value != '') {
          let val = JSON.parse(value)
          let language = this.language
          let val2 = val[language]
          if (val2.substring(0,1)==" " || val2.substring(val2.length-1)==" ") {
            callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
          }
          if (val2.length > 100) {
            callback(new Error(this.$t('cip.cmn.rule.nameLength10Warning')));
          } else {
            callback();
          }
        } else {
          callback(new Error(this.$t('cip.plat.sys.serialNumber.field.ruleName') + this.$t('cip.cmn.rule.noEmptyWarning')));
        }
      };
      return [
        {
          label: this.$t("cip.plat.sys.serialNumber.title.baseTitle"),
          labelWidth:150,
          prop: 'baseInfo',
          // icon: 'el-collapse-item',
          column: [
            {
              label: this.$t('cip.plat.sys.serialNumber.field.ruleCode'),
              prop: "ruleCode",
              maxlength:"100",
              showWordLimit:true,
              span: 8,
              disabled: this.viewFlag,
              rules: [{
                required: true,
                message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.serialNumber.field.ruleCode'),
                trigger: "blur"
              },
                {
                  pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
                  message: this.$t("cip.cmn.rule.deleteWarning"),
                }],
              placeholder: this.$t("cip.plat.sys.serialNumber.field.ruleCode"),
            },
            {
              label: this.$t('cip.plat.sys.serialNumber.field.ruleName'),
              prop: "ruleName",
              span: 8,
              isGlobal:true,
              readonly:this.viewFlag,
              rules: [{ required: true, validator: validatePass, trigger: 'blur' }],
              placeholder: this.$t("cip.plat.sys.serialNumber.field.ruleName"),
            },
            {
              label: this.$t('cip.plat.sys.serialNumber.field.ruleType'),
              prop: "ruleType",
              type: "select",
              dicUrl:"/api/sinoma-system/dict/dictionary?code=biz_num_rule",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              span: 8,
              disabled:this.viewFlag,
             rules: [
                {
                  required: true,
                  message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.serialNumber.field.ruleType'),
                  trigger: [ "blur","change"]
                },
              ],
              placeholder: this.$t("cip.plat.sys.serialNumber.field.ruleType"),
            },
            {
              label: this.$t('cip.plat.sys.serialNumber.field.serialLength'),
              prop: "serialLength",
              span: 8,
              type: 'number',
              minRows: 0,
              maxRows: 1000000000,
              readonly: this.viewFlag,
              rules: [
                {
                  required: true,
                  message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t("cip.plat.sys.serialNumber.field.serialLength"),
                  trigger: "blur",
                },
              ],
              placeholder: this.$t("cip.plat.sys.serialNumber.field.serialLength"),
            },
            {
              label: this.$t('cip.plat.sys.serialNumber.field.currentValue'),
              prop: "currentValue",
              type: 'number',
              span: 8,
              minRows: 0,
              maxRows: 1000000000,
              readonly:this.viewFlag,
              rules: [
                {
                  required: true,
                  message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t("cip.plat.sys.serialNumber.field.currentValue"),
                  trigger: "blur",
                },
              ],
              placeholder: this.$t("cip.plat.sys.serialNumber.field.currentValue"),
            },
            {
              label: this.$t('cip.plat.sys.serialNumber.field.initValue'),
              prop: "initValue",
              type: 'number',
              span: 8,
              minRows: 0,
              maxRows: 1000000000,
              readonly:this.viewFlag,
              rules: [
                {
                  required: true,
                  message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t("cip.plat.sys.serialNumber.field.initValue"),
                  trigger: "blur",
                },
              ],
              placeholder: this.$t("cip.plat.sys.serialNumber.field.initValue"),
            },
            {
              label: this.$t('cip.plat.sys.serialNumber.field.step'),
              prop: "step",
              span: 8,
              type: 'number',
              minRows: 0,
              maxRows: 1000000000,
              readonly:this.viewFlag,
              rules: [
                {
                  required: true,
                  message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t("cip.plat.sys.serialNumber.field.step"),
                  trigger: "blur",
                },
              ],
              placeholder: this.$t("cip.plat.sys.serialNumber.field.step"),
            },
            {
              label: this.$t('cip.plat.sys.serialNumber.field.prefix'),
              prop: "prefix",
              span: 8,
              maxlength:"100",
              showWordLimit:true,
              readonly:this.viewFlag,
              rules: [{
                required: true,
                message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.serialNumber.field.prefix'),
                trigger: "blur"
              },
                {
                  pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
                  message: this.$t("cip.cmn.rule.deleteWarning"),
                }],
              placeholder: this.$t("cip.plat.sys.serialNumber.field.prefix"),
            },
            {
              label: this.$t('cip.plat.sys.serialNumber.field.curTime'),
              prop: "curTime",
              hide: true,
              type: "datetime",
              format: "yyyy-MM-dd HH:mm:ss",
              valueFormat: "yyyy-MM-dd HH:mm:ss",
              span: 8,
              readonly:this.viewFlag,
              rules: [{
                required: true,
                message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.serialNumber.field.curTime'),
                trigger: "blur"
              }],
              placeholder: this.$t("cip.plat.sys.serialNumber.field.curTime"),
            },
            {
              label: this.$t('cip.plat.sys.serialNumber.field.systemTime'),
              prop: "systemTime",
              type: "datetime",
              format: "yyyy-MM-dd HH:mm:ss",
              valueFormat: "yyyy-MM-dd HH:mm:ss",
              span: 8,
              readonly:this.viewFlag,
              rules: [{
                required: true,
                message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.serialNumber.field.systemTime'),
                trigger: "blur"
              }],
              placeholder: this.$t("cip.plat.sys.serialNumber.field.systemTime"),
            },
            {
              label: this.$t('cip.plat.sys.serialNumber.field.split'),
              prop: "split",
              maxlength:"100",
              showWordLimit:true,
              span: 8,
              readonly:this.viewFlag,
              placeholder: this.$t("cip.plat.sys.serialNumber.field.split"),
            }
          ]
        },
      ];
    },

  },
  methods: {
    //校验盘点数据完整性
    formCheck() {
      let result = true;
      if (this.form.ruleCode === null || this.form.ruleCode === '') {
        this.$message.warning(this.$t("cip.plat.sys.serialNumber.msg.select_warning"));
        return false;
      }
      return result;
    },
    submitbtn(type){
      this.submit(type);
    },
    //保存
    submit(type) {
      this.$refs.formLayout.getForm().validate(valid => {
          if (valid) {
            this.$loading()
            //主表判空
            if (!this.formCheck()) {
              this.saveLoading = false;
            } else {
              saveOrUpdate({
                ...this.form,
              }).then(res => {
                this.saveLoading = false;
                const {code, msg, data} = res.data;
                this.form = data
                if (code === 200) {
                  this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"))
                }
                if(type =='back'){
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                }
              }, () => {
                this.viewFlag=false
              }).finally(()=>{
                this.$loading().close()
                this.$refs.formLayout.$refs.form.allDisabled = false
              })
            }
          }})
    },
    //关闭：返回主页面
    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    formData(id) {
      //加载主表信息
      getDetail(id).then(res => {
        this.form = res.data.data;
      })
    },
  },
  created() {
    this.type = this.$route.query.type;
    if (this.type == 'add') {
      this.viewFlag = false;
    } else if (this.type == 'edit') {
      this.editFlag = true;
      this.viewFlag = false;
      this.formData(this.$route.query.id);
    }else if(this.type == 'view'){
      this.viewFlag = true;
      this.formData(this.$route.query.id);
    }
  },
};
</script>

<style scoped>

</style>
